import React, { useState, useEffect } from "react";

import Sidebar from "../../components/Sidebar";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import io from 'socket.io-client';
import wreloadqr from "./wreloadqr.png"

const socket = io(`https://dspsol.com/`);

function SocketQRCode() {
  const [qrCode, setQRCode] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const location = useLocation();
  const [retryClicked, setRetryClicked] = useState(false)

  const retryHandle = (e) => {
    e.preventDefault();
    setRetryClicked(true);
    setConnectionStatus("Please Wait...")
    if (
      userProfile &&
      userProfile.uid
    ) {

      socket.emit('AddDevice', {
        id: userProfile.uid,
      });
    }
  }

  //STEP II
  //SOCKET START
  const [connectionStatus, setConnectionStatus] = useState("")

  useEffect(() => {
    if (
      userProfile &&
      userProfile.uid
    ) {

      socket.on('connect', () => {
        setConnectionStatus("Connected to the server");
      });

      socket.on('disconnect', () => {
        setConnectionStatus("Disconnected to the server")
      });

      socket.on(userProfile.uid, (msg) => {

        if (msg.hasOwnProperty("qrCode")) {
          setQRCode(msg.qrCode)
          setConnectionStatus("QR Ready to Scan")
          setRetryClicked(false);
        }

        if (msg.message) {

          setConnectionStatus(msg.message);
          setQRCode("")

          if (
            msg.message === "Your device is connected" ||
            msg.message === "Your device is connected successfully!"
          ) {
            setQRCode("")
          }
        }

      });

    }

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off(userProfile.uid);
    };
  }, [userProfile]);

  //SOCKET END

  useEffect(() => {


    if (
      userProfile &&
      userProfile.uid
    ) {

      socket.emit('AddDevice', {
        id: userProfile.uid,
      });
    }
  }, [userProfile])

  //STEP I
  useEffect(() => {

    let userInfo = {
      ...location.state,
    };

    if (userInfo && userInfo.uid) setUserProfile(userInfo);
  }, []);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      <div className="body w-64 flex-1 p-8 bg-gray-100">

        <div
          id="qrcode"
          className="m-16 flex items-center justify-center p-8"
        ></div>

        {
          (connectionStatus === "Retry" || connectionStatus === "Your device is disconnected") && (
            <button onClick={retryHandle} disabled={retryClicked}>
              <img src={wreloadqr} alt="retry" />
            </button>
          )
        }

        <div className="flex flex-col justify-start">
          <div className="w-[339px] flex justify-center items-center">
            {qrCode !== "" && (
              <div>
                <QRCode value={qrCode} />
              </div>
            )}
          </div>
          <div className="text-lg font-mono text-green-500 normal-case leading-3 tracking-wides my-4 w-full text-left">
            {connectionStatus}
          </div>
        </div>
        <div>
          <span className="font-semibold tracking-widest font-2xl text-left text-red-400 mr-2">CAUTION:</span>
          <span className="font-xl tracking-wider font-semibold mb-4 text-left text-blue-400">
            Do not sign out from your account after device is
            connected successfully
          </span>
        </div>
      </div>
    </div>
  );
}

export default SocketQRCode;

