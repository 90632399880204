import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";

import ShopIcon from "@mui/icons-material/Shop";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#22C55E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const GET_ORDERS = gql`
  query($uid: String!){
  OrdersByUser(uid: $uid) {
        orderId
        name
        companyName
        gstin
        planType
        email
        mobile
        amount
        wallet
        status
        createdAt
  }
}
`;

const columns = [
  {
    id: "createdAt",
    label: "Date",
    minWidth: 130,
    align: "left",
    format: (value) => {
      moment(new Date(Number(value)))
        .utc()
        .format("DD-MM-YYYY")
    },
  },
  {
    id: "orderId",
    label: "Order Id",
    minWidth: 130,
    align: "left",
  },
  { id: "planType", label: "Plan Name", minWidth: 170, align: "left" },
  { id: "name", label: "Name", minWidth: 170, align: "left" },
  { id: "companyName", label: "Company Name", minWidth: 170, align: "left" },
  { id: "gstin", label: "GSTIN", minWidth: 170, align: "left" },
  {
    id: "amount",
    label: "Amount",
    minWidth: 130,
    align: "center",
  },
  {
    id: "wallet",
    label: "Wallet",
    minWidth: 130,
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 130,
    align: "center",
  },
];

function Order() {
  let location = useLocation();

  const userInfo = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  const [order, setOrder] = useState(null);
  const [userOrdersData, setUserOrdersData] = useState([]);
  const [showCouponInput, setShowCouponInput] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let val = event.target.value ? +event.target.value : 0;
    setRowsPerPage(val);
    setPage(0);
  };

  const { data, loading, error } = useQuery(GET_ORDERS, {
    variables: {
      uid: userInfo.uid
    }
  });

  const changeHandler = (e) => {
    setOrder((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const resetOrder = () => {
    setOrder(null);
  }

  const couponHandler = (e) => {
    if (e.target.value === "TMAP23" && order.planType !== "MAP 8A") {
      setOrder((prevData) => {
        return { ...prevData, amount: order.amount - ((order.amount * 15) / 100) };
      });
    }
  }

  useEffect(() => {
    if (data) {
      let { OrdersByUser } = data;

      setUserOrdersData(OrdersByUser);
    }
  }, [data, loading]);

  useEffect(() => {

    if (
      order &&
      order.planType !== "MAP 8A"
    ) {
      setShowCouponInput(true);
    }
  }, [order])

  useEffect(() => {
    setOrder(location.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-screen">
      <Sidebar resetOrder={resetOrder} />
      <div className="body w-64 flex-1  p-8">
        {order != null && (
          <div className="mx-auto flex w-full flex-col px-0 md:flex-row">
            <div className="flex w-full flex-col">
              <h2 className="text-heading mb-4 font-bold md:text-xl ">
                Plan Purchase
              </h2>
              <form
                className="mx-auto w-full justify-center"
                method="post"
                action="https://dspsol.com/paynow"
              >
                <input
                  name="userId"
                  type="hidden"
                  placeholder="userId"
                  value={userInfo.id}
                  onChange={changeHandler}
                />
                <input
                  name="internetHandlingCharge"
                  type="hidden"
                  placeholder="internetHandlingCharge"
                  value="0"
                />
                <input
                  name="planDetails"
                  type="hidden"
                  placeholder="planDetails"
                  value={JSON.stringify(location.state)}
                  onChange={changeHandler}
                />
                <input
                  name="customerId"
                  type="hidden"
                  placeholder="customerId"
                  value={userInfo.uid}
                  onChange={changeHandler}
                />
                <div className="">
                  <div className="space-x-0 lg:flex lg:space-x-4">
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="firstName"
                        className="mb-3 block text-sm font-semibold text-gray-500"
                      >
                        Name
                      </label>
                      <input
                        name="name"
                        type="text"
                        placeholder="Name"
                        required
                        onChange={changeHandler}
                        className="w-full rounded border border-gray-300 px-4 py-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 lg:text-sm"
                      />
                    </div>
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="Email"
                        className="mb-3 block text-sm font-semibold text-gray-500"
                      >
                        Email
                      </label>
                      <input
                        name="email"
                        type="email"
                        placeholder="Email"
                        required
                        onChange={changeHandler}
                        className="w-full rounded border border-gray-300 px-4 py-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 lg:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-1 space-x-0 lg:flex lg:space-x-4">
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="Phone"
                        className="mb-3 block text-sm font-semibold text-gray-500"
                      >
                        Phone
                      </label>
                      <input
                        name="phone"
                        type="phone"
                        placeholder="Phone"
                        required
                        onChange={changeHandler}
                        className="w-full rounded border border-gray-300 px-4 py-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 lg:text-sm"
                      />
                    </div>
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="companyName"
                        className="mb-3 block text-sm font-semibold text-gray-500"
                      >
                        Company Name (Optional)
                      </label>
                      <input
                        className="w-full rounded border border-gray-300 px-4 py-3 text-xs focus:outline-none focus:ring-1 focus:ring-blue-600 lg:text-sm"
                        name="companyName"
                        type="text"
                        placeholder="Company Name"
                        onChange={changeHandler}
                      ></input>
                    </div>
                  </div>
                  <div className="mt-1 space-x-0 lg:flex lg:space-x-4">
                    <div className="w-full lg:w-1/2">
                      <label
                        htmlFor="gstin"
                        className="mb-3 block text-sm font-semibold text-gray-500"
                      >
                        GSTIN (Optional)
                      </label>
                      <input
                        name="gstin"
                        type="text"
                        placeholder="GSTIN"
                        onChange={changeHandler}
                        className="w-full rounded border border-gray-300 px-4 py-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 lg:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-1">
                    <div className="flex w-full justify-between lg:w-1/2">
                      <label className="mb-3 block text-sm font-semibold text-gray-500">
                        Plan Type
                      </label>
                      <input
                        className="mr-2 font-medium text-indigo-500 "
                        value={order.planType}
                        name="planType"
                        onChange={changeHandler}
                      />
                    </div>
                    <div>
                      <div className="mt-1">
                        <div className="flex w-full justify-between lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold text-gray-500">
                            Wallet Amount
                          </label>
                          <input
                            className="mr-2 font-medium text-indigo-500 "
                            value={order.wallet}
                            name="wallet"
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="mt-1">
                        <div className="flex w-full justify-between lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold text-gray-500">
                            Total Credits
                          </label>
                          <input
                            className="mr-2 font-medium text-indigo-500 "
                            value={order.credits}
                            name="totalCredits"
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="mt-1">
                        <div className="flex w-full justify-between lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold text-gray-500">
                            Gross Amount
                          </label>
                          <input
                            className="mr-2 font-medium text-indigo-500 "
                            name="grossAmount"
                            value={order.amount}
                            onChange={changeHandler}
                          />
                        </div>
                        {showCouponInput && (<div className="flex w-full justify-between lg:w-1/2 my-2">
                          <label className="mb-3 block text-sm font-semibold text-gray-500">
                            Coupon Code
                          </label>
                          <input
                            className="mr-2 font-medium text-indigo-500 "
                            name="couponCode"
                            onChange={couponHandler}
                          />
                        </div>)}
                      </div>
                      <div className="mt-1">
                        <div className="flex w-full justify-between lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold text-gray-500">
                            GST 18%
                          </label>
                          <input
                            className="mr-2 font-medium text-indigo-500 "
                            name="gst"
                            value={(order.amount * 18) / 100}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                      {/* <div className="mt-1">
                        <div className="flex w-full justify-between lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold text-gray-500">
                            Internet Handling Charge
                          </label>
                          <input
                            className="mr-2 font-medium text-indigo-500 "
                            name="internetHandlingCharge"
                            value={(order.amount * 0) / 100}
                            onChange={changeHandler}
                          />
                        </div>
                      </div> */}
                      <div className="mt-1">
                        <div className="flex w-full justify-between lg:w-1/2">
                          <label className="mb-3 block text-sm font-semibold text-gray-500">
                            Total Amount
                          </label>
                          <input
                            type="number"
                            className="mr-2 font-medium text-indigo-500 "
                            name="totalAmount"
                            value={
                              Number(order.amount) +
                              Number(order.amount * 18) / 100

                            }
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-1">
                    {userInfo.id ? <button
                      type="submit"
                      className=" bg-blue-600 px-6 py-2 text-blue-200 hover:bg-blue-900"
                    >
                      Checkout
                    </button> : <button
                      disabled
                      className=" bg-blue-600 px-6 py-2 text-blue-200 hover:bg-blue-900"
                      title="Login Once Again"
                    >
                      Checkout
                    </button>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {order == null && (
          <div>
            <h1 className="mb-4 rounded  p-2 text-center text-2xl font-semibold tracking-wider text-green-300 shadow-xl">
              My Orders
            </h1>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <div className="mb-4">
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userOrdersData &&
                        userOrdersData.length > 0 &&
                        userOrdersData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.id === "createdAt"
                                        ? moment
                                          .unix(value / 1000)
                                          .format("DD-MM-YYYY hh:mm a")
                                        : column.format && typeof value === "number"
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={userOrdersData ? userOrdersData.length : 0}
                  rowsPerPage={rowsPerPage ? rowsPerPage : 0}
                  page={page ? page : 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Paper>
          </div>
        )}
      </div>
    </div>
  );
}

export default Order;


