import React, { useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Link, Phone } from "@mui/icons-material";
import { useState } from "react";
import moment from "moment";

const GET_USER = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      uid
      resellerId
      name
      userType
      doj
      wallet
      planType
      totalMessages
      totalMessagesSent
      isConnected
      expireInDays
    }
  }
`;

const ALL_ADVERTISE_GET = gql`
  query {
    advertises {
      id
      message
      downloadURL
      from
      to
      callToAction
      visitUs
      createdAt
    }
  }
`;

const Card = (props) => {
  return (
    <div className="m-4 w-52 flex-1 rounded-md bg-[#22C55E] p-4 text-white">
      <h3 className="text-center uppercase">{props ? props.title : ""}</h3>
      <p className="text-semibold overflow-auto text-center text-yellow-200">
        {props ? props.body : ""}
      </p>
      <p className="text-center font-thin uppercase text-orange-400">
        {props.subtitle}
      </p>
    </div>
  );
};

const AdCard = (props) => {
  return (
    <div className="block w-[500px] rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <div
        className="relative m-1 overflow-hidden bg-cover bg-no-repeat p-1"
        data-te-ripple-init=""
        data-te-ripple-color="light"
      >
        <img
          className="w-full  rounded-t-lg object-cover"
          src={props.img}
          // src="https://tecdn.b-cdn.net/img/new/standard/nature/186.jpg"
          alt={props.alt}
        />
        {/* <a href="#!">
          <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100" />
        </a> */}
      </div>
      <div className="p-3">
        {/* <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
          Card title
        </h5>
        <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
          Some quick example text to build on the card title and make up the bulk of
          the card's content.
        </p> */}
        <div className="flex justify-center space-x-2">
          {props.callToAction && (
            <a
              href={`tel:${props.callToAction}`}
              target="_blank"
              className="hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700 inline-block rounded bg-green-400 p-1 px-6 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init=""
              data-te-ripple-color="light"
            >
              <Phone />
              {props.callToAction}
            </a>
          )}
          {props.visitUs && (
            <a
              href={props.visitUs}
              target="_blank"
              className="hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700 inline-block rounded bg-blue-400 p-1 px-6 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init=""
              data-te-ripple-color="light"
            >
              <Link />
              Visit Us
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

function Home() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [allAd, setAllAd] = useState("");

  let { uid } = JSON.parse(localStorage.getItem("user")) || {};

  const setUserProfile = (userProfile) => {
    if (userProfile) {
      if (userProfile.userType === "Reseller") {
        setData(userProfile);
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const { data: adData, loading: adLoading } = useQuery(ALL_ADVERTISE_GET);

  useEffect(() => {
    let ads = null;
    if (adData) {
      ads = adData.advertises;
      setAllAd(ads);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adData, adLoading]);

  const {
    data: userData,
    loading: userLoading,
    refetch,
  } = useQuery(GET_USER, {
    variables: { uid },
  });

  useEffect(() => {
    let userProfile = null;
    if (userData) {
      userProfile = userData.user;
      setUserProfile(userProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userLoading]);

  useEffect(() => {
    refetch({ uid: uid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      <div className="body flex-1 bg-green-50">
        {!userLoading && data ? (
          <div>
            <h1 className="mt-4 mb-4 text-center text-2xl font-semibold text-green-600">
              Welcome back {data.name}
            </h1>

            <div className="mt-11 flex flex-wrap justify-around">
              <div className="m-4 w-52 flex-1 rounded-md bg-[#22C55E] p-4 text-white">
                <div className="justify-left flex items-center space-x-2">
                  <p>Total Credits</p>
                  <p> : </p>
                  <p>{data.totalMessages}</p>
                </div>

                <div className="justify-left flex  items-center space-x-2">
                  <p>Used Credits</p>
                  <p> : </p>
                  <p>{data.totalMessagesSent}</p>
                </div>
                {Number(data.wallet) === 0 && (
                  <div className="justify-left flex  items-center space-x-2">
                    <p>Remaining Credits</p>
                    <p> : </p>
                    <p>
                      {Number(data.totalMessages) -
                        Number(data.totalMessagesSent)}
                    </p>
                  </div>
                )}

                <div className="justify-left flex  items-center space-x-2">
                  <p>Wallet</p>
                  <p> : </p>
                  <p>{Number(data.wallet)}</p>
                </div>

                <div className="justify-left flex  items-center space-x-2">
                  <p>Plan Type</p>
                  <p> : </p>
                  <p>{data.planType}</p>
                </div>
              </div>
              <Card
                title="Expiry Date"
                body={moment(Number(data.doj))
                  .add(data.expireInDays, "days")
                  .utc()
                  .format("DD-MM-YYYY")}
                subtitle={
                  moment(Number(data.doj))
                    .add(data.expireInDays, "days")
                    .utc()
                    .format("YYYY-MM-DD") >
                    moment(Number(new Date().getTime()))
                      .add(0, "days")
                      .utc()
                      .format("YYYY-MM-DD")
                    ? ""
                    : "EXPIRED"
                }
              ></Card>
              <Card
                title="Device Status"
                body={data.isConnected ? "CONNECTED" : "DISCONNECTED"}
                subtitle=""
              ></Card>
              <Card title="User ID" body={data.uid} subtitle=""></Card>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Home;
