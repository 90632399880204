import React, { useContext, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Alerts from "../../components/Alerts";
import Logo from "./logo.png";

import axios from "axios";
import { useEffect } from "react";

const Login = () => {
  const [error, setError] = useState("");

  const [userProfile, setUserProfile] = useState(null);
  const [isPending, setIsPending] = useState(false)


  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const [visibility, setVisibility] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleEmailChange = (e) => {
    e.preventDefault();
    emailRef.current = e.target.value;
  };

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    passwordRef.current = e.target.value;
  };

  const handleVerify = () => {

    let data = JSON.stringify({
      "subject": "Account Verify",
      "fromEmail": "info@dspsol.com",
      "toEmail": emailRef.current,
      "html": `
<!doctype html>
<html lang="en-US">
<head>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <title>Account verify Email </title>
    <meta name="description" content="Account verify Email .">
    <style type="text/css">
        a:hover {text-decoration: underline !important;}
    </style>
</head>

<body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
    <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
        style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
        <tr>
            <td>
                <table style="background-color: #f2f3f8; max-width:670px; margin:0 auto;" width="100%" border="0"
                    align="center" cellpadding="0" cellspacing="0">
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <a href="https://dspsol.com/" title="logo" target="_blank">
                            <img width="60" src="https://dspsol.com/static/media/logo.654f7eda2a2f3c99c52f.png" title="logo" alt="logo">
                          </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                style="max-width:670px; background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style="padding:0 35px;">
                                        <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Get started
                                        </h1>
                                        <p style="font-size:15px; color:#455056; margin:8px 0 0; line-height:24px;">
                                            Your account has been created on the Message API. Below are your account verify link, <br></p>
                                        <span
                                            style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>


                                        <a href="https://dspsol.com/verifyaccount?uid=${userProfile?.uid}&fid=${userProfile?.id}"
                                            style="background:#20e277;text-decoration:none !important; display:inline-block; font-weight:500; margin-top:24px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Verify your Account</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>dspsol.com</strong> </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>

</html>`,
      "attachments": []
    });

    let config = {
      method: 'post',
      url: 'https://dspsol.com/mail/sendmail',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        setError("Check your mail box after one or two mins to verify your account")
        setIsPending(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
        setIsPending(false);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsPending(true);

    if (emailRef.current && passwordRef.current) {
      let data = JSON.stringify({
        query: `query ($email: String!, $password: String!) {
          login(email: $email, password: $password) {
            id
            uid
            email
            planType
            userType
            resellerId
            isVerified
          }
        }`,
        variables: {
          email: emailRef.current,
          password: passwordRef.current,
        },
      });

      let config = {
        method: "post",
        url: "https://graphql.dspsol.com/",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {

          let {
            data: { login: user },
          } = response.data;

          if (user) {
            setUserProfile(user);
          } else {
            setError("Check email or password")
            setIsPending(false);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setIsPending(false);
        });
    }
  };

  useEffect(() => {
    if (userProfile) {

      //CHECK IS A DEMO ACCOUNT
      if (userProfile.planType.trim() === "DEMO") {

        //CHECK IS USER VERIFIED
        if (!userProfile.isVerified) {

          if (userProfile) handleVerify();
          return;
        }
      }

      if (userProfile && userProfile.userType === "Reseller") {
        dispatch({ type: "LOGIN", payload: userProfile });

        if (userProfile && Object.entries(userProfile).length > 0)
          localStorage.setItem("user", JSON.stringify(userProfile));

        navigate("/dashboard");
      } else {
        setIsPending(false);
        setError("You are not a reseller");
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 10000)
  }, [error])

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="Tally Konnect" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          SIGN IN
        </h2>
        <p className="max-w mt-2 text-center text-sm text-gray-400">
          Not registered yet ?
          <Link
            to="/signup"
            className="hover:text-blue-400focus:outline-none ml-2 font-medium text-blue-400 focus:ring-2 focus:ring-blue-500"
          >
            Sign up
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-5/12">
        <div className="rounded-lg bg-white py-8 px-6 shadow sm:px-10">
          <form className="mb-0 space-y-6" onSubmit={handleLogin}>
            <div>
              <label htmlFor="email">Email</label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  ref={emailRef}
                  onChange={handleEmailChange}
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="relative mt-1">
                <input
                  id="password"
                  name="password"
                  type={visibility ? "text" : "password"}
                  ref={passwordRef}
                  onChange={handlePasswordChange}
                  required
                />
                {visibility ? (
                  <VisibilityOffIcon
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => handleVisibility()}
                  />
                ) : (
                  <VisibilityIcon
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => handleVisibility()}
                  />
                )}
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex items-center">
                <input
                  id="terms-and-privacy"
                  name="terms-and-privacy"
                  type="checkbox"
                />
                <label
                  htmlFor="terms-and-privacy"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>
              <div>
                <Link
                  to="/resetpassword"
                  className="hover:text-blue-400focus:outline-none ml-2 font-medium text-blue-400 focus:ring-2 focus:ring-blue-500"
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
            <div className="flex w-full flex-col items-center space-y-2">
              {!isPending && (
                <button
                  type="submit"
                  className="w-1/2 rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Log In
                </button>
              )}
              {isPending && (
                <button
                  type="submit"
                  className="w-1/2 rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  disabled
                >
                  Wait....
                </button>
              )}
              {error && <Alerts alertType="Error">{error}</Alerts>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
